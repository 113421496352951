import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'menu-actions',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [NgClass, MatMenu, MatMenuItem, MatMenuTrigger, MatIconButton, MatIcon],
  templateUrl: './menu-actions.component.html',
  styleUrl: './menu-actions.component.scss'
})
export class MenuActionsComponent {
  @Input() menuOptions: any;
  @Input() config: any;
  @Output() emitAction = new EventEmitter();
  className = 'menu-actions';

  showOptions() {
    if (document.getElementById('view')) {
      document.getElementById('view').onclick = (event) => {
        this.chooseActions(this.config.type, this.config, 'view');
      };
    }
    if (document.getElementById('forceEnable')) {
      document.getElementById('forceEnable').onclick = (event) => {
        this.chooseActions(this.config.type, this.config, 'enable');
      };
    }
    if (document.getElementById('forceDisable')) {
      document.getElementById('forceDisable').onclick = (event) => {
        this.chooseActions(this.config.type, this.config, 'disable');
      };
    }
    if (document.getElementById('showCredentials')) {
      document.getElementById('showCredentials').onclick = (event) => {
        this.chooseActions(this.config.type, this.config, 'showCredentials');
      };
    }
    if (document.getElementById('uploadConfig')) {
      document.getElementById('uploadConfig').onclick = (event) => {
        this.chooseActions(this.config.type, this.config, 'uploadConfig');
      };
    }
    if (document.getElementById('editInterface')) {
      document.getElementById('editInterface').onclick = (event) => {
        this.chooseActions(this.config.type, this.config, 'editInterface');
      };
    }
    if (document.getElementById('downloadConfig')) {
      document.getElementById('downloadConfig').onclick = (event) => {
        this.chooseActions(this.config.type, this.config, 'downloadConfig');
      };
    }
    if (document.getElementById('deleteAppConfig')) {
      document.getElementById('deleteAppConfig').onclick = (event) => {
        this.chooseActions(this.config.type, this.config, 'deleteAppConfig');
      };
    }
    if (document.getElementById('delete')) {
      document.getElementById('delete').onclick = (event) => {
        this.chooseActions(this.config.type, this.config.id, 'delete');
      };
    }

    if (document.getElementById('regeneratePsk')) {
      document.getElementById('regeneratePsk').onclick = (event) => {
        this.chooseActions(this.config.type, this.config, 'regeneratePsk');
      };
    }
  }

  chooseActions(type, config, action) {
    this.emitAction.emit({ type, config, action });
  }

  removeEventListeners() {
    document.removeEventListener('click', this.showOptions);
  }

  ngOnDestroy() {
    this.removeEventListeners();
  }
}