<button
  (click)="showOptions()"
  [disabled]="config.type === 'vpn' && config.statusConfig === 'noIp'"
  [matMenuTriggerFor]="menuToggler"
  class="big"
  mat-icon-button>
  <mat-icon class="material-symbols-outlined">more_vert</mat-icon>
</button>
<mat-menu #menuToggler="matMenu">
  @for (data of menuOptions; track data.action) {
    <menu
      [id]="data.action"
      class="{{ className }}__menu-toggle-item"
      [innerHTML]="data.element"
      mat-menu-item></menu>
  }
</mat-menu>