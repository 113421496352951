<div
  #customconfig
  class="{{ mode === 'view' ? 'custom-config' : 'custom-config__edit' }} {{
    modeBatch ? 'batch' : ''
  }}">
  <div class="{{ className }}__container">
    <div class="{{ className }}__container-title">
      <h2
        class="{{ className }}__title"
        matTooltip="{{ title }}"
        matTooltipClass="custom-tooltip"
        matTooltipPosition="above">
        {{ title }}
      </h2>
       @if(appName==='global' && mode==='view'){
         <h5
           class="{{className}}__subtitle"
         >
           Sent: {{
             formatDate(appConfig.lastUpdate)}}
         </h5>
       }
    </div>
    <div
      *ngIf="action === 'import-config' + spaceId"
      class="{{ className }}__wrapper-upload"
      #containerModal>
      <div class="{{ className }}__import-config-header">
        <input
          #fileInputNew
          (change)="
            selectAction({
              event: $event,
              action: 'import-config',
              selectedConfigId: '',
              isModeDrag: false
            })
          "
          accept="application/json"
          style="display: none"
          type="file" />
        <h3 class="{{ className }}__title-wrapper">
          <mat-icon class="material-symbols-outlined {{ className }}__title-icon">upload</mat-icon>
          <span class="{{ className }}__title-load">Upload Config</span>
        </h3>
      </div>
      <div
        (click)="fileInputNew.click()"
        (files)="filesDropped($event)"
        appDrag
        class="{{ className }}__import-config-container">
        <div class="{{ className }}__import-config-container-wrapper">
          <mat-icon
            class="material-symbols-outlined {{ className }}__import-config-container-wrapper-icon">
            upload
          </mat-icon>
          <span class="{{ className }}__import-config-container-wrapper-text">Search or drag</span>
        </div>

      </div>
      <div *ngIf="errorMessage" class="{{ className }}__error">{{ errorMessage }}</div>

    </div>
    <div class="{{ className }}__wrapper-buttons">
      <ng-container *ngIf="mode === 'edit'">
        <button
          (click)="
            this.action === 'load-config' ? hideComponent() : loadComponent($event, 'load-config')
          "
          class=" {{ 'button-icon' + (action === 'load-config' ? ' active' : '') }}"
          id="{{ className }}__{{
            id | lowercase | replace : ' ' : '-' | replace : '_' : '-' | replace : '.' : '-'
          }}-{{
            appName | lowercase | replace : ' ' : '-' | replace : '_' : '-' | replace : '.' : '-'
          }}-load-config"
          mat-icon-button
          matTooltip="{{ labelsCommon.load + ' ' + labelsCommon.config }}"
          matTooltipClass="custom-tooltip"
          matTooltipPosition="above">
          <mat-icon class="material-symbols-outlined">folder_open</mat-icon>
        </button>
        <button
          (click)="
            this.action === 'save-config' ? hideComponent() : loadComponent($event, 'save-config')
          "
          class=" {{ 'button-icon' + (action === 'save-config' ? ' active' : '') }}"
          id="{{ className }}__{{
            id | lowercase | replace : ' ' : '-' | replace : '_' : '-' | replace : '.' : '-'
          }}-{{
            appName | lowercase | replace : ' ' : '-' | replace : '_' : '-' | replace : '.' : '-'
          }}-save-config"
          mat-icon-button
          matTooltip="{{ labelsCommon.save + ' ' + labelsCommon.config }}"
          matTooltipClass="custom-tooltip"
          matTooltipPosition="above">
          <mat-icon class="material-icons-outlined">save</mat-icon>
        </button>

      </ng-container>
      <ng-container *ngIf="mode !== 'edit'">
        <button
          (click)="changeModeEditor('edit')"
          class=" {{ 'button-icon' + (action === 'edit' ? ' active' : '') }}"
          id="{{ className }}__{{
            id | lowercase | replace : ' ' : '-' | replace : '_' : '-' | replace : '.' : '-'
          }}-{{
            appName | lowercase | replace : ' ' : '-' | replace : '_' : '-' | replace : '.' : '-'
          }}-edit-config"
          mat-icon-button
          matTooltip="{{ labelsCommon.editConfig }}"
          matTooltipClass="custom-tooltip"
          matTooltipPosition="above">
          <mat-icon class="material-icons-outlined">edit</mat-icon>
        </button>
        <menu-actions
          (emitAction)="chooseActions($event)"
          [menuOptions]="generateMenuOptions()"
          [config]="config"></menu-actions>
      </ng-container>
    </div>
  </div>
  <div
    *ngIf="mode !== 'view' &&!( action === '' || action==='import-config') "
    class="{{ className }}__wrapper-component {{ '' ? 'padding' : '' }}">
    <ng-container [ngSwitch]="action">
      <ng-container *ngSwitchCase="'load-config'">
        <h3 class="{{ className }}__title-wrapper">
          <mat-icon class="material-symbols-outlined {{ className }}__title-icon"
          >folder_open</mat-icon
          >
          <span class="{{ className }}__title-load">Load Config</span>
        </h3>
        <config-load
          (emitAction)="selectAction($event)"
          [id]="id"
          [idConfigSelected]="idConfigSelected"
          [dataSelectConfiguration]="dataSelectConfiguration"
          [modeBatch]="modeBatch"
          [mode]="mode"></config-load>
      </ng-container>
      <ng-container *ngSwitchCase="'save-config'">
        <div
          class="{{ className }}__save-config {{
            mode === 'view' ? 'custom-config' : 'custom-config__edit'
          }} {{ modeBatch ? 'batch' : '' }}">
          <h3 class="{{ className }}__title-wrapper">
            <mat-icon class="material-symbols-outlined {{ className }}__title-icon">save</mat-icon>
            <span class="{{ className }}__title-load">Save Config</span>
          </h3>
          <div class="{{ className }}__wrapper-form-save">
            <mat-form-field>
              <mat-label>Name</mat-label>
              <input
                #nameSaveAppConfig
                [(ngModel)]="appName"
                matInput
                maxlength="60"
                type="string" />
              <mat-hint align="end">{{ nameSaveAppConfig.value.length }} / 60</mat-hint>
            </mat-form-field>
            <span class="{{ className }}__error">{{ messageError }}</span>
          </div>
          <div
            class="{{ className }}__footer {{ modeBatch ? 'batch' : '' }}">
            <button
              (click)="selectAction({ action: 'close', selectedConfigId: '' })"
              color="dark-blue-inverted"
              id="{{ className }}-button-cancel"
              mat-flat-button>
              {{ labelsCommon.cancel }}
            </button>
            <button
              (click)="selectAction({ action: 'save-config', selectedConfigId: appName })"
              [disabled]="appName === ''"
              color="dark-blue"
              id="{{ className }}-load-button"
              mat-flat-button>
              {{ labelsCommon.save  }}
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <ng-container>
    <div *ngIf="mode !== 'edit'" class="{{ className }}__json-editor">
      <json-editor
        #editor_view
        (change)="getData($event)"
        [data]="appConfig.config"
        [options]="optionsConfig"></json-editor>
    </div>
    <div *ngIf="mode === 'edit'" class="{{ className }}__json-editor">
      <json-editor
        #editor_edit
        (change)="getData($event)"
        [data]="data"
        [options]="optionsConfig"></json-editor>
    </div>
  </ng-container>
</div>