import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { ActionsAppConfigService } from '../../../app-config/services/actions-app-config.service';
import { EditJsonConfigComponent } from '../../../devices/device-detail/components/edit-json-config/edit-json-config.component';
import { CardService } from '../../../devices/services/card.service';
import { commonLabels } from '../../common.labels';
import { CommonLabels, ConfigModel } from '../../models/common.model';
import { ResizeWindowService } from '../../services/resize-window.service';
import { SnackMessageService } from '../../services/snackMessage.service';
import { StoreCommonService } from '../../services/store-common.service';
import { UtilsService } from '../../services/utils.service';
import { ModalConfirmConfigurableComponent } from '../modal-confirm-configurable/modal-confirm-configurable.component';
import { FileHandle } from './dragDrop.directive';
import { DeviceStoreService } from '../../../store/device-store.service';

@AutoUnsubscribe()
@Component({
  selector: 'custom-config',
  templateUrl: './custom-config.component.html',
  styleUrls: ['./custom-config.component.scss']
})
export class CustomConfigComponent implements OnChanges, OnInit, OnDestroy {
  @Output() emitError: EventEmitter<any> = new EventEmitter();
  @Output() emitJson: EventEmitter<any> = new EventEmitter();
  @Input() optionsConfig: JsonEditorOptions;
  @Output() emitAction: EventEmitter<any> = new EventEmitter();
  @Input() appConfig: { config: any; lastUpdate: any; _id?: string };
  @Input() dataSpace?: any;
  @Input() width: number;
  @Input() type: string;
  @Input() id: string;
  @Input() spaceId?: any;
  @Input() mode?: string = 'view';
  @Input() appName?: string;
  @Input() device: any;
  @Input() modeBatch?: boolean;
  @ViewChild('editor_view') editor_view: JsonEditorComponent;
  @ViewChild('editor_edit') editor_edit: JsonEditorComponent;
  @ViewChild('fileInput') fileInput: any;
  @ViewChild('containerModal', { static: false }) containerModalUpload: ElementRef;
  @ViewChild('customconfig', { static: false }) customconfigElement: ElementRef;
  className: string = 'custom-config';
  labelsCommon: CommonLabels = commonLabels;
  action: string = '';
  dataSelectConfiguration: ConfigModel[];
  data: any;
  fileResult: any;
  disableSave: boolean = false;
  messageError: string;
  dataSend: { config: any; lastUpdate: string };
  fileName?: string = '';
  defaultAppConfig = 'Global Config';
  saveAndLoad: boolean = false;
  title: string = '';
  files: FileHandle[] = [];
  errorMessage: string;
  subtitle: string = '';
  config = { type: 'delete' };
  storeDevice = inject(DeviceStoreService);
  idConfigSelected: any;

  constructor(
    private utilsService: UtilsService,
    private matDialog: MatDialog,
    private actionsAppConfigService: ActionsAppConfigService,
    private snackMessageService: SnackMessageService,
    private resizeWindowService: ResizeWindowService,
    private cardService: CardService,
    private storeCommonService: StoreCommonService
  ) {}

  ngOnInit() {
    this.data = this.appConfig.config;
    this.getAppConfigGlobal();

    this.storeCommonService.getAction().subscribe((action) => {
      this.action = action;
      if (this.action === '') {
        document.removeEventListener('click', this.handleClickOutsideListener);
      }
    });
    this.getTitle();
  }
  getTitle() {
    if (this.id !== 'global-config') {
      this.dataSpace.appConfig.current.config.appConfigId === '000000000000000000000000'
        ? (this.title = 'App Config')
        : this.getConfigSaved(this.dataSpace.appConfig.current.config.appConfigId);
      this.idConfigSelected = this.dataSpace.appConfig.current.config.appConfigId;
    } else {
      this.device.deviceConfig.appConfig.current.config.appConfigId === '000000000000000000000000'
        ? (this.title = 'Untitled')
        : this.getConfigSaved(this.device.deviceConfig.appConfig.current.config.appConfigId);
      this.idConfigSelected = this.device.deviceConfig.appConfig.current.config.appConfigId;
    }
  }
  getConfigSaved(id: string) {
    return this.actionsAppConfigService.getConfigById(id).subscribe({
      next: (response: any) => {
        this.title = response.name;
      },
      error: (error) => {
        this.snackMessageService.readError(error);
      }
    });
  }

  filesDropped(files: FileHandle[]): void {
    if (files[0].file.type === 'application/json.json') {
      this.files = files;
      this.importValues(this.files, true, 'import-config');
      this.fileInput.nativeElement.value = '';
    } else {
      this.snackMessageService.sendMessage('Config failed to upload, please try again.');
    }
  }

  getData(event = null) {
    this.dataSelectConfiguration.filter((item) => {
      if (item._id === this.idConfigSelected) {
        const appConfig = JSON.parse(this.utilsService.conversorString(item.appConfig));
        const appConfig2 = event;
        if (JSON.stringify(appConfig) !== JSON.stringify(appConfig2)) {
          if (!this.title.endsWith(' *')) {
            this.title = this.title + ' *';
            delete this.appConfig._id;
          }
        } else {
          this.title = this.title.split(' *').join('');
          this.appConfig._id = item._id;
        }
      }
    });
    if (!(event instanceof Event)) {
      if (this.modeBatch) {
        this.appConfig.config = event;
      }
      this.emitJson.emit(event);
      this.emitError.emit(event instanceof Event);
    }

    if (typeof event === 'object') {
      if (event.type === 'change') {
        try {
          this.emitJson.emit(this.editor_edit.get());
        } catch (e) {
          this.emitError.emit(true);
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.appConfig) {
      this.appConfig = changes.appConfig.currentValue;
    }
    this.optionsConfig.onValidationError = (errors) => {
      if (errors.length > 0) {
        this.emitError.emit(errors);
      } else {
        this.emitError.emit(false);
      }
    };
  }

  getAppConfigGlobal(): void {
    this.actionsAppConfigService
      .getConfig(this.id === 'global-config' ? 'global' : 'spaces')
      .subscribe({
        next: (response: ConfigModel[]) => {
          this.dataSelectConfiguration = response;
        },
        error: (error) => {
          this.snackMessageService.readError(error);
        }
      });
  }

  chooseActions({ type, config, action }) {
    switch (action) {
      case 'deleteAppConfig':
        this.deleteConfig(config);
        break;
      case 'uploadConfig':
        this.importConfig({ type, config, action });
        break;
      case 'downloadConfig':
        this.createFile();
        break;
    }
  }
  generateMenuOptions(): any {
    const options = [];

    options.push(
      {
        element:
          '<span   class="material-symbols-outlined menu-advanced rotate-180"> file_download </span>Upload Config',
        action: 'uploadConfig'
      },
      {
        element:
          '<span   class="material-symbols-outlined menu-advanced"> file_download </span>Download Config',
        action: 'downloadConfig'
      },
      {
        element:
          '<span class="material-symbols-outlined menu-advanced">delete</span>Delete App Config',
        action: 'deleteAppConfig'
      }
    );

    return options;
  }

  changeModeEditor(mode: string) {
    delete this.appConfig._id;
    this.optionsConfig.mode = 'code';
    this.storeCommonService.setAction('');
    this.matDialog
      .open(EditJsonConfigComponent, {
        height: 'auto',
        width: this.width > 650 ? '80vw' : '100vw',
        maxHeight: '100vh',
        maxWidth: '100vw',
        enterAnimationDuration: 1,
        exitAnimationDuration: 2,
        panelClass: 'custom-dialog-container',
        backdropClass: 'backdropBackground',
        autoFocus: false,
        data: {
          appConfig: this.appConfig,
          modeBatch: this.modeBatch,
          id: this.id,
          dataSpace: this.dataSpace,
          appName: this.appName,
          type: this.type,
          device: this.device,
          spaceId: this.spaceId,
          mode: this.mode
        }
      })
      .afterClosed()
      .subscribe((data) => {
        if (data) {
          document.removeEventListener('click', this.handleClickOutsideListener);
          this.customconfigElement.nativeElement.style.height = 'auto';
          setTimeout(() => {
            this.getTitle();
          }, 2000);
        } else {
          this.storeDevice.getJsonConfig().subscribe({
            next: (data) => {
              if (
                JSON.stringify(this.utilsService.conversorString(data.current.config.appConfig)) !==
                JSON.stringify(this.appConfig.config)
              ) {
                this.appConfig.config = JSON.parse(
                  this.utilsService.conversorString(data.current.config.appConfig)
                );
              }
            }
          });

          this.customconfigElement.nativeElement.style.height = 'auto';
          this.storeCommonService.setAction('');
          this.emitAction.emit(this.action);
          document.removeEventListener('click', this.handleClickOutsideListener);
        }
      });
  }

  hideComponent(): void {
    this.storeCommonService.setAction('');
    this.emitAction.emit(this.action);
  }

  loadComponent(event, action): void {
    let name = '';
    this.storeCommonService.setAction(action);
    this.emitAction.emit(this.action);
    if (this.id !== 'global-config') {
      name = `${this.appName} Config`;
    } else {
      name = this.defaultAppConfig;
    }
    const dName = this.resizeWindowService.getPartsDate(new Date());
    let namePart1 =
      '' +
      dName[0] +
      (dName[1] + 1 < 10 ? '0' + (dName[1] + 1) : dName[1] + 1 > 12 ? dName[1] : dName[1] + 1) +
      (dName[2] < 10 ? '0' + dName[2] : dName[2]) +
      (dName[3] < 10 ? '0' + dName[3] : dName[3]) +
      (dName[4] < 10 ? '0' + dName[4] : dName[4]) +
      (dName[5] < 10 ? '0' + dName[5] : dName[5]);

    switch (action) {
      case 'load-config':
        this.saveAndLoad = true;
        break;
      case 'save-config':
        this.saveAndLoad = true;
        this.appName = namePart1 + ' - ' + name;
        this.messageError = undefined;
        break;
    }
  }

  jsonSendBtnStatus(): void {
    if (this.disableSave) {
      this.dataSend = this.mode === 'edit' ? this.data : this.appConfig;
      //this.emitJson.emit(this.data);
    } else {
      this.dataSend = this.mode === 'edit' ? this.data : this.appConfig;
      this.getData(this.dataSend);
    }
  }

  loadConfig(event) {
    if (event.selectedConfigId !== '' && event.selectedConfigId !== null) {
      const select = this.dataSelectConfiguration.find(
        (item) => item._id === event.selectedConfigId
      );
      this.appConfig._id = select._id;
      this.idConfigSelected = select._id;
      this.title = select.name;
      this.data = JSON.parse(this.utilsService.conversorString(select.appConfig));
    }
    this.jsonSendBtnStatus();
    this.storeCommonService.setAction('');
    this.emitAction.emit('');
  }

  saveConfig(event) {
    const config: any = {
      name: event.selectedConfigId,
      isGlobal: this.id === 'global-config',
      description: '',
      appConfig: this.utilsService.conversorBase64(JSON.stringify(this.data))
    };
    this.data = this.appConfig.config;
    this.actionsAppConfigService.saveConfig(config).subscribe({
      next: (dataConfig) => {
        this.messageError = '';
        this.snackMessageService.sendMessage('S008');
        this.getAppConfigGlobal();
        this.storeCommonService.setAction('');
        this.emitAction.emit('');
        this.saveAndLoad = false;
        this.idConfigSelected = dataConfig._id;
        this.title = event.selectedConfigId;
        this.appConfig._id = this.idConfigSelected;

        this.jsonSendBtnStatus();
      },
      error: (error) => {
        error.error.response.errorCode === 'E089'
          ? (this.messageError = 'Name already exists')
          : this.snackMessageService.readError(error);
      }
    });
  }

  importConfig(event) {
    this.action === `${'import-config' + this.spaceId}`
      ? this.storeCommonService.setAction('')
      : this.storeCommonService.setAction(`${'import-config' + this.spaceId}`);
    if (this.action === '') {
      this.errorMessage = '';
      document.removeEventListener('click', this.handleClickOutsideListener);
      this.customconfigElement.nativeElement.style.height = 'auto';
    } else {
      if (!this.modeBatch) {
        this.customconfigElement.nativeElement.style.height = '345px';
      }

      setTimeout(() => {
        document.addEventListener('click', this.handleClickOutsideListener);
      }, 300);
    }

    if (!event.isModeDrag) {
      this.importValues(event.event.target.files, event.isModeDrag, 'import-config');
      this.fileInput.nativeElement.value = '';
    }
  }

  deleteConfig(event) {
    this.matDialog
      .open(ModalConfirmConfigurableComponent, {
        height: 'auto',
        width: this.width > 650 ? '400px' : '100vw',
        maxHeight: '100vh',
        maxWidth: '100vw',
        panelClass: 'custom-dialog-container',
        backdropClass: 'backdropBackground',
        disableClose: false,
        autoFocus: false,
        data: {
          titleMessage: 'Delete Global Config',
          contentMessage:
            'Do you really want to delete the global configuration contents in your Edge Node?' +
            '<br>' +
            '<br>' +
            'This action cannot be undone.',
          button1Message: 'Delete',
          button1Color: 'dark-blue'
        }
      })
      .afterClosed()
      .subscribe(
        (data) => {
          if (data) {
            this.data = {};
            delete this.appConfig._id;
            this.snackMessageService.sendMessage('appConfig deleted');
            this.appConfig.config = {};
            this.cardService.selectSendConfig(
              { type: this.type, device: this.device, spaceId: this.spaceId },
              this.appConfig.config
            );
            setTimeout(() => {
              this.getTitle();
            }, 2000);
          }
        },
        (error) => {
          this.snackMessageService.readError(error);
        }
      );
  }

  closeConfig() {
    this.storeCommonService.setAction('');
    this.emitAction.emit('');
    this.saveAndLoad = false;
    this.jsonSendBtnStatus();
  }

  selectAction(event) {
    switch (event.action) {
      case 'load-config':
        this.loadConfig(event);
        break;
      case 'save-config':
        this.saveConfig(event);
        break;
      case 'import-config':
        this.importConfig(event);
        break;
      case 'update-config':
        this.updateValues();
        break;
      case 'export-config':
        this.createFile();
        break;
      case 'delete-config':
        this.deleteConfig(event);
        break;
      case 'close':
        this.closeConfig();
        break;
    }
  }

  handleClickOutside(event) {
    let self: any = this;

    if (
      self.containerModalUpload &&
      !self.containerModalUpload.nativeElement.contains(event.target)
    ) {
      if (!self.containerModalUpload.nativeElement.contains(event.target)) {
        self.action = '';
        self.customconfigElement.nativeElement.style.height = 'auto';
        document.removeEventListener('click', this.handleClickOutsideListener);
        this.errorMessage = '';
      }
    }
  }

  createFile(): void {
    let data = JSON.stringify(this.appConfig.config);
    const blob = new Blob([data], { type: 'json' });
    const url = window.URL.createObjectURL(blob);
    const dwldLink = document.createElement('a');
    let isSafariBrowser =
      navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', this.appName + '_config.json');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  updateValues(): void {
    this.appConfig.config = JSON.parse(this.fileResult.toString());
    this.data = this.appConfig.config;
    document.removeEventListener('click', this.handleClickOutsideListener);
    this.jsonSendBtnStatus();
  }

  importValues(event, drag, action): void {
    const fileList: FileList = event;
    if (fileList) {
      const file = drag ? event[0].file : fileList[0];
      const size = Math.floor((file.size / 1024 / 1024) * 100) / 100;
      if (size <= 500) {
        this.fileName = file.name;
        let fileReader: FileReader = new FileReader();
        let self: any = this;
        fileReader.onloadend = function (x) {
          self.fileResult = fileReader.result;
        };
        fileReader.readAsText(file);
      }

      setTimeout(() => {
        try {
          this.updateValues();
          // this.storeCommonService.setAction('');
          if (!this.modeBatch) {
            this.changeModeEditor('edit');
          }
        } catch (e) {
          this.errorMessage = 'Config failed to upload, please try again.';
          this.customconfigElement.nativeElement.style.height = '395px';
          this.storeCommonService.setAction(`${'import-config' + this.spaceId}`);
        }
      }, 500);
    }
  }

  formatDate(date: Date): string {
    return this.utilsService.getTimeAgoWithHours(date);
  }

  ngOnDestroy() {
    console.log('destroy custom');
  }

  private handleClickOutsideListener = (event) => this.handleClickOutside(event);
}
