import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { StorageService } from '../../../main/common/services/storage.service';
import { navigation, navigationAdmin } from '../../../navigation/navigation';
import { AuthService } from '../../../main/common/services/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { CommonLabels } from 'app/main/common/models/common.model';
import { commonLabels } from 'app/main/common/common.labels';
import { DeviceStoreService } from '../../../main/store/device-store.service';
import { ResizeWindowService } from '../../../main/common/services/resize-window.service';
import { ActionsDeviceService } from '../../../main/devices/services/actions-device.service';
import { UsersService } from '../../../main/users/services/users.service';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../../../environments/environment';
import { SnackMessageService } from '../../../main/common/services/snackMessage.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

@AutoUnsubscribe()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() isDesktop: boolean;
  @Input() userRoles: string[];
  @Input() isAdmin: boolean;
  className = 'header';
  showEcoUser = false;
  labels: CommonLabels = commonLabels;
  nameUser: string;
  avatarUri: string;
  showMenu = false;
  showEcosystem = false;
  contentMenu = navigation;
  listDeviceRecent = [];
  selected = '';
  url: string;
  width: number;
  userMainRole: string;
  userRolesList: string[];
  protected readonly environment = environment;

  constructor(
    private storageService: StorageService,
    private router: Router,
    private authService: AuthService,
    private resizeWindowService: ResizeWindowService,
    private deviceStoreService: DeviceStoreService,
    private usersService: UsersService,
    private actionsDeviceService: ActionsDeviceService,
    private readonly keycloak: KeycloakService,
    private snackMessageService: SnackMessageService
  ) {}

  ngOnInit() {
    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        this.url = item.url;
      }
    });

    this.authService.getUserAvatar().subscribe((avatarUri) => {
      this.avatarUri = avatarUri ? avatarUri : 'assets/images/avatars/profile.jpg';
    });

    if (this.isAdmin) {
      this.contentMenu = navigationAdmin;
    } else {
      this.contentMenu = navigation;
      this.authService.getUserName().subscribe((name) => {
        this.nameUser = name;
      });
      this.authService.getMainRol().subscribe((userMainRol) => {
        this.userMainRole = userMainRol;
      });
      this.authService.getUserRolesList().subscribe((userRolesList) => {
        this.userRolesList = userRolesList;
      });
      // this.usersService.getListOfDevicesRecent().subscribe(
      //   (listDeviceRecent) => {
      //     if (listDeviceRecent.length === 0) {
      //       this.selected = 'noData';
      //     }
      //     this.listDeviceRecent = listDeviceRecent;
      //     this.selectDevice();
      //   },
      //   (error) => {
      //     this.snackMessageService.readError(error);
      //   }
      // );
      // this.deviceStoreService.getDeviceListRecent().subscribe(
      //   (listDeviceRecent) => {
      //     if (listDeviceRecent.length === 0) {
      //       this.selected = 'noData';
      //     }
      //     this.listDeviceRecent = listDeviceRecent;
      //     this.selectDevice();
      //   },
      //   (error) => {
      //     this.snackMessageService.readError(error);
      //   }
      // );
    }

    this.resizeWindowService.width$.subscribe((value: number) => {
      this.width = value;
    });
    this.formatMenu();
  }

  showOrHideMenu(option) {
    this.showMenu = !this.showMenu;
    this.showEcosystem = false;
    if (option.externalUrl) {
      window.open(option.url, '_blank');
    } else {
      this.router.navigate([option.url]);
    }
  }

  openUrl(url: string, target?: string) {
    this.showEcosystem = !this.showEcosystem;
    window.open(url, target !== undefined ? target : '_blank');
  }

  selectDevice() {
    this.deviceStoreService.getSelectedDevice().subscribe((deviceId) => {
      this.selected = deviceId;
    });
  }

  public goToDeviceDetails = (device): void => {
    this.storageService.setDeviceId(device.deviceId);
    this.storageService.setDeviceName(device.deviceName);
    this.actionsDeviceService.getDevice(device.deviceId).subscribe({
      error: (err) => this.snackMessageService.readError(err)
    });
    this.deviceStoreService.selectedDevice(device.deviceId);
    this.deviceStoreService.changeRecentDevice(true);
    this.router.navigate(['/devices/detail']);
  };

  closeSession(): void {
    this.logout();
    this.storageService.removelocalStorageData();
    this.storageService.removeFilters();
    this.authService.setUserLoggedIn(false);
    this.router.navigate(['/']);
  }

  public logout() {
    this.keycloak.logout();
  }

  formatMenu() {
    const permissionDeploy = this.authService.hasDeplyPermission();
    const permissionUser = this.authService.hasUserPermission();

    if (!permissionDeploy) {
      const idx = this.contentMenu.findIndex((item) => item.id === 'groups');
      this.contentMenu.splice(idx, 1);
    }
    if (!permissionUser) {
      const idx = this.contentMenu.findIndex((item) => item.id === 'usermgmt');
      this.contentMenu.splice(idx, 1);
    }
  }

  listDevice() {
    this.actionsDeviceService.getAllDevice(0, 1000, '', '', '').subscribe({
      next: (data) => {
        this.listDeviceRecent.forEach((item, index) => {
          const device = data.deviceList.find((device: any) => device._id === item.deviceId);
          if (device) {
            item.deviceName = device.name;
          } else {
            this.listDeviceRecent.splice(index, 1);
            this.usersService
              .saveListOfDevicesRecent(this.listDeviceRecent)
              .subscribe({ error: (err) => this.snackMessageService.readError(err) });
          }
        });
      },
      error: (error) => {
        this.snackMessageService.readError(error);
      }
    });
  }

  ngOnDestroy(): void {}
}
