<div class="{{ className }}__container">
  <div class="{{ className }}__container-left">
    <mat-icon
      (click)="showMenu = true; showEcosystem = false; showEcoUser = false"
      *ngIf="!showMenu"
      TooltipTouchGestures="on"
      class="{{ className }}__menu-icon"
      id="{{ className }}-open-menu"
      matTooltip="{{ labels.showMenu }}"
      matTooltipClass="custom-tooltip"
      >menu
    </mat-icon>
    <mat-icon
      (click)="showMenu = false; showEcosystem = false; showEcoUser = false"
      *ngIf="showMenu"
      TooltipTouchGestures="on"
      class="{{ className }}__menu-icon"
      id="{{ className }}-closed-menu"
      matTooltip="{{ labels.hideMenu }}"
      matTooltipClass="custom-tooltip"
      >close
    </mat-icon>
    <div class="{{ className }}__logo">
      <a
        class="{{ className }}__logo-link"
        id="{{ className }}-logo-link"
        routerLink="/devices/devices-list">
        <h1 style="display: none">Barbara Panel</h1>
        <ng-container *ngIf="width >= 780; else mobile">
          <img
            alt="logo barbara panel"
            class="{{ className }}__logo-icon"
            src="/assets/images/logos/panel_logo.svg" />
        </ng-container>
        <ng-template #mobile>
          <img
            alt="ico barbara"
            class="{{ className }}__logo-icon"
            src="/assets/images/logos/panel_avatar.svg" />
        </ng-template>
      </a>
    </div>
  </div>

  <div *ngIf="isDesktop && !isAdmin" class="{{ className }}__container-center">
    <div
      *ngIf="false"
      class="{{ className }}__container-icon"
      routerLinkActive="{{ className }}__container-icon--active">
      <a
        #link0="routerLinkActive"
        (click)="showMenu = false"
        class="{{ className }}__container-link"
        routerLink="/home"
        routerLinkActive="active">
        <mat-icon
          *ngIf="!link0.isActive"
          class="material-icons-outlined {{ className }}__container-link-icon"
          >home
        </mat-icon>
        <mat-icon
          *ngIf="link0.isActive"
          class="material-icons-sharp {{ className }}__container-link-icon"
          >home
        </mat-icon>
        <span class="{{ className }}__icon-text">{{ labels.home }}</span>
      </a>
    </div>
    <div
      class="{{ className }}__container-icon"
      routerLinkActive="{{ className }}__container-icon--active">
      <a
        #link1="routerLinkActive"
        (click)="showMenu = false"
        class="{{ className }}__container-link"
        routerLink="/application/list"
        routerLinkActive="active">
        <mat-icon
          *ngIf="!link1.isActive"
          class="material-icons-outlined {{ className }}__container-link-icon"
          >collections_bookmark
        </mat-icon>
        <mat-icon
          *ngIf="link1.isActive"
          class="material-icons-sharp {{ className }}__container-link-icon"
          >collections_bookmark
        </mat-icon>
        <span class="{{ className }}__icon-text">{{ labels.library }}</span>
      </a>
    </div>
    <div
      class="{{ className }}__container-icon"
      routerLinkActive="{{ className }}__container-icon--active">
      <a
        #link2="routerLinkActive"
        (click)="showMenu = false"
        class="{{ className }}__container-link"
        routerLink="/running-applications/list"
        routerLinkActive="active">
        <mat-icon
          *ngIf="!link2.isActive"
          class="material-symbols-outlined {{ className }}__container-link-icon">
          assistant_on_hub
        </mat-icon>
        <mat-icon
          *ngIf="link2.isActive"
          class="material-symbols-outlined {{ className }}__container-link-icon">
          assistant_on_hub
        </mat-icon>
        <span class="{{ className }}__icon-text">{{ labels.apps }}</span>
      </a>
    </div>
    <div
      class="{{ className }}__container-icon"
      routerLinkActive="{{ className }}__container-icon--active">
      <a
        #link3="routerLinkActive"
        (click)="showMenu = false"
        class="{{ className }}__container-link"
        routerLink="/devices/devices-list"
        routerLinkActive="active">
        <mat-icon
          *ngIf="!link3.isActive"
          class="material-symbols-outlined {{ className }}__container-link-icon">
          router
        </mat-icon>
        <mat-icon
          *ngIf="link3.isActive"
          class="material-symbols-outlined {{ className }}__container-link-icon">
          router
        </mat-icon>
        <span class="{{ className }}__icon-text">{{ labels.edgeNodes }}</span>
      </a>
    </div>

  </div>
  <div class="{{ className }}__container-right">
    <div class="{{ className }}__container-feedback">
      <img
        (click)="openUrl(environment.helpdesk, 'barbara')"
        TooltipTouchGestures="on"
        alt="Get support"
        class="{{ className }}__icon-ecosystem"
        matTooltip="Get support"
        matTooltipClass="custom-tooltip"
        src="assets/icons/feedback.svg" />
    </div>
    <div class="{{ className }}__container-ecosystem">
      <mat-icon
        (click)="showEcosystem = true; showMenu = false; showEcoUser = false"
        *ngIf="!showEcosystem"
        TooltipTouchGestures="on"
        class="{{ className }}__icon-ecosystem"
        matTooltip="{{ labels.showEcosystem }}"
        matTooltipClass="custom-tooltip">
        dialpad
      </mat-icon>
      <mat-icon
        (click)="showEcosystem = false; showMenu = false; showEcoUser = false"
        *ngIf="showEcosystem"
        TooltipTouchGestures="on"
        class="{{ className }}__icon-ecosystem-close"
        matTooltip="{{ labels.hideEcosystem }}"
        matTooltipClass="custom-tooltip">
        close
      </mat-icon>
      <ng-container *ngIf="showEcosystem">
        <div class="{{ className }}__eco-container">
          <ul class="{{ className }}__menu-eco">
            <li class="{{ className }}__menu-eco-item">
              <a
                (click)="openUrl('https://barbara.tech', 'barbara')"
                class="{{ className }}__menu-eco-link"
                id="barbara-link"
                rel="noopener">
                <img
                  alt="ico barbara"
                  class="{{ className }}__menu-eco-icon"
                  src="assets/images/logos/ecosystem_barbara.svg" />
              </a>
            </li>
            <li class="{{ className }}__menu-eco-item">
              <a
                (click)="openUrl(environment.academyUrl, 'barbaraAcademy')"
                class="{{ className }}__menu-eco-link"
                id="academy-link"
                rel="noopener">
                <img
                  alt="ico academy"
                  class="{{ className }}__menu-eco-icon"
                  src="assets/images/logos/ecosystem_academy.svg" />
              </a>
            </li>
            <li class="{{ className }}__menu-eco-item">
              <a
                (click)="openUrl(environment.marketUrl, 'barbaraMarket')"
                class="{{ className }}__menu-eco-link"
                id="market-link"
                rel="noopener">
                <img
                  alt="ico barbara"
                  class="{{ className }}__menu-eco-icon"
                  src="/assets/images/logos/ecosystem_market.svg" />
              </a>
            </li>
            <li *ngIf="false" class="{{ className }}__menu-eco-item">
              <a
                (click)="openUrl('https://studio.barbaraiot.com/', 'barbaraStudio')"
                class="{{ className }}__menu-eco-link"
                id="studio-link"
                rel="noopener">
                <img
                  alt="ico studio"
                  class="{{ className }}__menu-eco-icon"
                  src="assets/images/logos/ecosystem_studio.svg" />
              </a>
            </li>
          </ul>
        </div>
      </ng-container>
    </div>
    <div
      (click)="showMenu = false; showEcosystem = false; this.showEcoUser = !this.showEcoUser"
      TooltipTouchGestures="on"
      class="{{ className }}__container-right-link"
      matTooltip="{{ labels.account }}"
      matTooltipClass="custom-tooltip">
      <div>
        <img
          [src]="avatarUri"
          alt="avatar"
          class="{{ className }}__avatar"
          id="{{ className }}__user-avatar-image" />
      </div>
      <div *ngIf="isDesktop" class="{{ className }}__name" id="{{ className }}__user-name">
        {{ nameUser }}
      </div>
      <ng-container *ngIf="showEcoUser">
        <div class="{{ className }}__eco-container user">
          <ul class="{{ className }}__menu-eco user">
            <li
              *ngIf="isAdmin === false"
              class="{{ className }}__menu-eco-item"
              id="{{ className }}__link-edit-profile"
              routerLink="/account/profile">
              <a class="{{ className }}__menu-eco-link">
                <mat-icon class="material-icons-sharp">person_outline</mat-icon>
                <span class="{{ className }}__menu-eco-item-text">Edit profile</span>
              </a>
            </li>
            <li
              *ngIf="userMainRole === 'role_company_admin'"
              class="{{ className }}__menu-eco-item"
              id="{{ className }}__link-edit-organization"
              routerLink="/users/userlist">
              <a class="{{ className }}__menu-eco-link">
                <mat-icon class="material-icons-sharp">business</mat-icon>
                <span class="{{ className }}__menu-eco-item-text">Edit organization</span>
              </a>
            </li>
            <li
              (click)="closeSession()"
              class="{{ className }}__menu-eco-item"
              id="{{ className }}__link-close-session">
              <a class="{{ className }}__menu-eco-link">
                <mat-icon>logout</mat-icon>
                <span class="{{ className }}__menu-eco-item-text">Logout</span>
              </a>
            </li>
          </ul>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-container *ngIf="showMenu">
  <ul [ngClass]="showMenu ? 'header__menu header__menu--active' : 'header__menu'">
    <li
      #container
      *ngFor="let option of contentMenu"
      [ngClass]="option.url === url ? 'header__menu--option-active' : ''"
      class="{{ className }}__menu-row">
      <a
        (click)="showOrHideMenu(option)"
        [ngClass]="option.borderTop ? 'header__menu-name-border header__menu-name' : ''"
        class="header__menu-name"
        id="{{ option.id }}">
        <mat-icon class="{{ option.classFont }}">
          {{ option.icon }}
        </mat-icon>
        <span class="{{ className }}__menu-text">{{ option.title }}</span>
      </a>
    </li>
  </ul>
</ng-container>
<div
  (click)="showEcosystem = false; showMenu = false; showEcoUser = false"
  *ngIf="showEcosystem || showMenu || showEcoUser"
  class="header__menu-overlay"></div>